/* ///////////////////////////////// */
/* //////////// RESET ////////////// */
/* ///////////////////////////////// */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;600&display=swap'); */

/* @font-face {
  font-family: 'Satoshi Variable';
  src: url('./Assets/fonts/Satoshi-Variable.ttf') format('truetype');
  font-display: swap;
  Add more font variations here (bold, italic, etc.) if needed
} */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /* user-select: none; */
  border-style: solid;
  font-family: Poppins, sans-serif !important;
}

button,
a {
  cursor: pointer !important;
}

html {
  overflow-x: hidden;
  overflow-y: overlay;
}

html body #root {
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
  padding: 0;
  margin: 0;
  background-repeat: no-repeat;
  scrollbar-width: none;
}

button:hover {
  opacity: 0.8;
}

body iframe {
  position: fixed;
  z-index: -999;
}

body .HW_frame {
  position: initial;
  z-index: initial;
}

ol,
ul {
  list-style: none;
  margin-bottom: 0rem;
}

h5 {
  margin: 0px !important;
}

textarea,
input {
  webkit-user-select: auto !important;
  user-select: auto !important;
}

@media (max-width: 768px) {
  input,
  textarea {
    font-size: 16px !important;
  }
  input::placeholder,
  textarea::placeholder {
    font-size: 16px !important;
  }
}

.MuiPaper-rounded {
  border-radius: 14px !important;
}

.sr-cart-action {
  background: #000 !important;
}
